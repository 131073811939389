import React, { useState } from 'react';
import axios from 'axios';
import './Gift.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

function Gift({ gift }) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [donorName, setDonorName] = useState('');
  const [amount, setAmount] = useState('');


  

  const handleToggleSelect = () => {
    setShowForm(!showForm);
  };

  const handleBuy = (e) => {
    setShowForm(!showForm);
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URL}/api/submit-payment`, {
      giftId: gift.id,
      donorName,
      amount: parseFloat(amount)
    })
    .then(response => {
      setPaymentInfo(response.data.paymentInfo);
    })
    .catch(error => {
      console.error('There was an error submitting the payment!', error);
    });

  };
  const progressPercentage = (gift.amountPaid / gift.price) * 100;
  const handleName = i18n.language === 'en' ? gift.englishName : gift.name;

  return (
    <div className="gift">
      <h4>{handleName}</h4>
      <img 
        src={gift.picture} 
        alt={gift.name} 
        style={{ width: '100%', height: 'auto' }}
      />
      {gift.price > 0 && (
        <>
          <p className='price'>{t('price')}: {gift.price}€</p>
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
          </div>
          <p>{t('amount_paid')}: {gift.amountPaid}€</p>
        </>
      )}
      <button onClick={handleToggleSelect} className="select-button">
        {showForm ? t('unselect') : t('select')}
      </button>
      {showForm && (
        <form onSubmit={handleBuy}>
          <div>
            <label>{t('name')}:</label>
            <input 
              type="text" 
              value={donorName} 
              onChange={(e) => setDonorName(e.target.value)} 
              required 
            />
          </div>
          <div>
            <label>{t('amount')}:</label>
            <input 
              type="number" 
              value={amount} 
              onChange={(e) => setAmount(e.target.value)} 
              required 
            />
          </div>
          <button type="submit">{t('buy')}</button>
        </form>
      )}
      {paymentInfo && (
        <div className="payment-info">
          <h3>{t('payment_info')}</h3>
          <p>{t('amount')}: {paymentInfo.amount}€</p>
          <p>{t('variable_symbol')}: {paymentInfo.variableSymbol}</p>
          <p>{t('iban')}: {paymentInfo.iban}</p>
          <img src={paymentInfo.qrCode} alt="PayBySquare QR Code" />
        </div>
      )}
    </div>
  );
}

export default Gift;
