import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from './oznamko.svg';
import pairPhoto from './pair_photo.JPG'; // Import the image

import './Oznamko.css';

function Oznamko() {
  const { t } = useTranslation();

  return (
    <div id="oznamko" className="oznamko">
        {/* add here centered image oznamko.svg with white rectangle behind it which is larger that the image */}
                <Logo className="oznamko-svg" />
    </div>
    
  );
}

export default Oznamko;
