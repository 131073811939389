import React from 'react';
import { Link } from 'react-scroll';
import Welcome from './Welcome';
import RSVP from './RSVP';
import Gifts from './Gifts';
import Photos from './Photos';
import Oznamko from './Oznamko';
import CouplePhoto from './CouplePhoto';
import './App.css'; // Import the CSS file
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import the i18n configuration
import { useState } from "react";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n: {changeLanguage, language} } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "pt" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  }
  return (

    <div className="App">
      <script src="https://kit.fontawesome.com/58c1d4c34e.js" crossorigin="anonymous"></script>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
      <nav>
        <ul>
          <li><Link to="oznamko" smooth={true} duration={500}>{t('nav_welcome')}</Link></li>
          <li><Link to="rsvp" smooth={true} duration={500}>{t('nav_rsvp')}</Link></li>
          <li><Link to="gifts" smooth={true} duration={500}>{t('nav_gifts')}</Link></li>
          <li><Link to="photos" smooth={true} duration={500}>{t('nav_photos')}</Link></li>
          <button 
        type="button" 
        onClick={handleChangeLanguage}><i class="fa fa-language fa-lg"></i></button>
        </ul> 
      </nav>
      <section id="oznamko">
        <Oznamko />
      </section>
      <section id="couple-photo">
        <CouplePhoto /> 
      </section>
      {/* <section id="welcome">
        <Welcome />
      </section> */}
      <section id="rsvp">
        <RSVP />
      </section>
      <section id="gifts">
        <Gifts />
      </section>
      <section id="photos">
        <Photos />
      </section>
      
    </div>
  );
}

export default App;
