import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from './oznamko.svg';
import pairPhoto from './pair_photo.JPG'; // Import the image

import './CouplePhoto.css';

function CouplePhoto() {
  const { t } = useTranslation();

  return (
    <div id="couple-photo" className="couple-phpoto">
        {/* add here centered image oznamko.svg with white rectangle behind it which is larger that the image */}
        <div className="pair-photo-wrapper">
          <img src={pairPhoto} alt="Pair Photo" id="pair_photo"/>
        </div>
    </div>
    
  );
}

export default CouplePhoto;
